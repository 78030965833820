<template>
  <div class="Resources">
    <div class="case-title">
      <div>
        <div class="icon_title">
            <img class="case-title_icon" src="@/assets/images/imgAll/icon_zypz_@2x.png" alt />
            <div class="case-title_max">小程序版本更新发布功能说明</div>
        </div>
        <div class="icon_tip">按每次发布的版本添加济南繁星回收门店端、回收端小程序功能更新说明，请规范填写更新功能说明（将向用户端展示）</div>
      </div>
    </div>
    <div class="tab_click" v-if="!formShow">
      <div class="tabRound">
          <div :class="clickTab == '01' ? 'left':'right'" @click="getItme('01')">门店端小程序</div>
          <div :class="clickTab == '02' ? 'left':'right'" @click="getItme('02')">回收端小程序</div>
      </div>
    </div>
    <div class="add_version" v-if="!formShow">
        <el-button size="medium" type="primary" icon="el-icon-plus" round @click="handBtn('add')" >新增发布</el-button>
    </div>
    <div class="xcx_table" v-if="!formShow">
        <XcxEditionTable></XcxEditionTable>
    </div>
    <div class="Xcx_form" v-if="formShow">
      <XcxEditionFrom :versionId="versionId"></XcxEditionFrom>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import XcxEditionTable from './Table'
import XcxEditionFrom from './From'
export default {
  components: {XcxEditionTable,XcxEditionFrom},
  data() {
    return {
      clickTab: "01",
      formShow: false,
      versionId: ""
    };
  },
  created(){
    this.getItme('01')
  },
  methods: {
    getItme(index) {
      this.clickTab = index
        const SeachParams = {
        platform:index,
        pageNum:1,
        pageSize:10,
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    handBtn(type,id){
      console.log(id);
      console.log(type);
      this.versionId = id || null
      if(type=='add'){
        this.formShow = true
      }else if(type=='back'){
        this.formShow = false
      }
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Resources {
  width: 100%;
  min-height: 800px;
  background: #ffffff;
  box-shadow: 0px 8px 31px 0px rgba(20, 49, 127, 0.08);
  border-radius: 20px;
  .tabRound{
    user-select: none;
    cursor: pointer;
    width: 280px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 16px;
    display: flex;
    .left{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      background: #0981FF;
      border-radius: 16px;
    }
    .right{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      border-radius: 16px;
    }
  }
  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0;
    .icon_title {
      display: flex;
      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .case-title_max {
        display: inline-block;
        font-size: 18px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
    .icon_tip{
        margin-left: 25px;
        color: #0981ff;
        font-size: 14px;
        padding: 20px 0 0 0;
    }
  }
  .tab_click {
    padding: 20px 0 0 20px;
    .input_phone{
      /deep/ .el-input{
        width: 340px;
      }
    }
  }
  .add_version{
      text-align: right;
      padding: 0 20px 20px;
  }
  .xcx_table{
      padding: 0 20px;
  }
  .Xcx_form{
    padding: 20px;
  }
}
</style>