<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
        <el-table-column width="200" label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
                <el-button size="mini" type="primary" round @click="handleBtn(row)">查看详情</el-button>
            </template>
        </el-table-column>
    </GlobalTable>
    <!--弹框-->
    <!-- <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="handleDialogClosed"
    >
      <div style="font-size:16px;color:#666666;text-align:center;height:100px;line-height:100px">确认要执行该操作吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  data() {
    return {
      loading: false,
      seachDataList: [],
      StaffEnable: {
        id: "",
        isEnable: ""
      },
      page:{
        pageNum:1,
        pageSize:10
      },
      pageNum:1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: 380,
      dialongDecide: "",
      tableColumns: [
        { label: "版本号", prop: "version" },
        { label: "功能简介", prop: "shortNote" },
        { label: "发布时间", prop: "releaseTime" },
        { label: "发布账号", prop: "updateUserAcc" },
        { label: "最后更新时间", prop: "updateTime" },
        { slotName: "operation" }
      ]
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.handleCurrentChange()
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        platform: this.SeachParams.platform,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.getVersionList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false
        }
      });
    },
    handleBtn(row){
      console.log(row);
      this.$parent.handBtn('add',row.id);
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
}
</style>
