<template>
  <div class="XcxFormDemo" v-if="this.ruleForm.releaseTime || versionId==null">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-form-item label="小程序终端：" prop="platform">
            <el-select v-model="ruleForm.platform" placeholder="请选择" >
                <el-option
                 v-for="item in optionsPlatform"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="小程序版本：" prop="version">
            <el-input v-model="ruleForm.version" placeholder="请输入版本号（如: V1.0.0，限10字符内）"></el-input>
        </el-form-item>
        <el-form-item label="发布时间：" prop="releaseTime">
            <el-date-picker
             :disabled="versionId != null"
             v-model="ruleForm.releaseTime"
             type="datetime"
             placeholder="选择发布时间"
             value-format="timestamp"
             :picker-options="pickerOptions"
             default-time="12:00:00">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="截止时间：" prop="stopTime">
            <el-date-picker
             v-model="ruleForm.stopTime"
             :disabled="versionId != null"
             type="datetime"
             :picker-options="pickerOptions"
             placeholder="选择截止时间"
             value-format="timestamp"
             default-time="12:00:00">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="功能简介：" prop="shortNote">
            <el-input type="textarea" :rows="3" maxlength="100" show-word-limit v-model="ruleForm.shortNote" placeholder="请输入功能简介（限100字内）"></el-input>
        </el-form-item>
        <el-form-item label="标题：" prop="title">
            <el-input v-model="ruleForm.title" maxlength="20" placeholder="请输入标题（限20字内）"></el-input>
        </el-form-item>
        <el-form-item label="标题简介：" prop="titleContent">
            <el-input v-model="ruleForm.titleContent" type="textarea" :rows="3" maxlength="80" show-word-limit placeholder="请输入标题简介（限80字内）"></el-input>
        </el-form-item>
        <el-form-item label="详细说明：" prop="content">
            <div style="color: #ff687b;font-size:12px">请在富文本框内填写本次更新的功能介绍（将展示在小程序端）</div>
            <j-editor
                @handleEditContent="handleEditContent"
                @handleSettingEditContent="handleSettingEditContent"
                type='content'
            ></j-editor>
        </el-form-item>
        <el-form-item>
            <el-button @click="resetForm">返回</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
    </el-form>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div style="font-size:16px;margin:16px 0">{{tipValue}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleClosed">{{dialogTitle=='提交确认'?'暂不提交':'暂不返回'}}</el-button>
        <el-button type="primary" @click.native="handleSuccess">{{dialogTitle=='提交确认'?'确认提交':'确认返回'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import editor from './component/editor'
export default {
  name: 'Form',
  components: {
    [editor.name]: editor
  },
  props:{
      versionId:{
        type: Number,
        default: null
      }
  },
  data() {
    return {
        dialogTitle: "",
        dialogVisible: false,
        tipValue: "",
        ruleForm: {
          platform: '',
          version: '',
          releaseTime: '',
          stopTime: '',
          shortNote: '',
          title: '',
          titleContent: '',
          content: ''
        },
        optionsPlatform:[{
           value: '01',
           label: '门店小程序'
         }, {
           value: '02',
           label: '回收小程序'
         },
        ],
        rules: {
          platform: [
            { required: true, message: '请选择小程序终端', trigger: 'change' }
          ],
          version: [
            { required: true, message: '请输入版本号', trigger: 'blur' },
            { min: 1, max: 10, message: '长度限10字符内', trigger: 'blur' }
          ],
          releaseTime: [
            { type: 'date', required: true, message: '请选择发布时间', trigger: 'change' }
          ],
          stopTime: [
            { type: 'date', required: true, message: '请选择截止时间', trigger: 'change' }
          ],
          shortNote: [
            { required: true, message: '请填写功能简介', trigger: 'blur' }
          ],
          title: [
            { required: true, message: '请填写标题', trigger: 'blur' }
          ],
          titleContent: [
            { required: true, message: '请填写标题简介', trigger: 'blur' }
          ],
          content: [
            { required: true, message: '请填写详细说明', trigger: 'blur' }
          ]
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
          }
        }
    }
  },
  created() {
    console.log(this.versionId);
    if(this.versionId){
        this.getVersionInfo(this.versionId)
    }
  },
  methods: {
    submitForm(formName) {
    this.$refs[formName].validate((valid) => {
        if (valid) {
            console.log(this.ruleForm);
            this.dialogTitle = "提交确认"
            this.tipValue = "提交后，信息将展示在小程序端，确认要提交吗？"
            this.dialogVisible = true
        } else {
            console.log('error submit!!');
            return false;
        }
      });
    },
    // 获取版本详情
    getVersionInfo(id){
        _api.getVersionInfo({id}).then(res => {
            console.log(res);
            if(res.code === 1){
                this.ruleForm = {
                  platform: res.data.platform,
                  version: res.data.version,
                  releaseTime: Date.parse(res.data.releaseTime),
                  stopTime: Date.parse(res.data.stopTime),
                  shortNote: res.data.shortNote,
                  title: res.data.title,
                  titleContent: res.data.titleContent,
                  content: res.data.content
                }
            }
        })
    },
    // 返回
    resetForm() {
        for(var key in this.ruleForm){
            if(this.ruleForm[key]){
                this.tipValue = "发布的版本信息还未保存，确认要返回吗？"
                this.dialogTitle = "提醒"
                this.dialogVisible=true
                return false; 
            }
        }
        this.$parent.handBtn('back');
    },
    handleEditContent (html, type) {
        this.ruleForm[type] = html
        this.$forceUpdate()
    },
    handleSettingEditContent (set, type) {
        console.log('handleSettingEditContent', this.ruleForm)
        console.log(set)
        console.log(type)
        set.html(this.ruleForm[type])
    },
    // 确认提交/确认关闭
    handleSuccess(){
        if(this.dialogTitle == '提交确认'){
            let params = this.ruleForm
            if(this.versionId){
                params.id = this.versionId
            }
            _api.saveVersionLog(params).then(res => {
                if(res.code === 1){
                    this.$message.success(res.msg)
                    this.$parent.handBtn('back');
                }
                this.dialogVisible = false
            })
        }else{
            this.$refs['ruleForm'].resetFields();
            this.$parent.handBtn('back');
        }
    },
    // 弹框取消
    handleClosed(){
        this.dialogVisible = false
    },
    // 弹窗关闭
    handleDialogClosed(){
        this.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.XcxFormDemo{
    width: 1150px;
    /deep/.el-select,.el-input,.el-date-editor,.el-textarea{
        width: 500px !important;
    }
    .el-picker-panel{
        z-index: 99999 !important;
    }
}
</style>
